import React, { FC, useEffect, useState } from 'react'
import { ChevronDownIcon } from '@chakra-ui/icons'
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack,
  Flex,
  Text,
  Box,
  VStack,
  useBreakpointValue,
  InputGroup,
  InputRightElement,
  List,
  ListItem,
  Tooltip,
  Divider,
  AbsoluteCenter,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { EarnIcon } from 'atg/icons/earn-icon'
import { EngageIcon } from 'atg/icons/engage-icon'
import { EnjoyIcon } from 'atg/icons/enjoy-icon'
import { RepeatIcon } from 'atg/icons/repeat-icon'
import { core, semantic } from 'composable/chakra/figma-tokens'
import { birthMonths, states } from 'composable/helpers/constants/account'
import { birthdayArray, statePickerItems } from 'composable/helpers/utils/account'
import atgAxiosInstance from 'helpers/axios-atg-helper'
import { useFormat } from 'helpers/hooks/useFormat'
import { addCAPIFbEvent, getCAPIrootEventsProps } from 'helpers/metaCapiHelper'
import { useLocale } from 'frontastic/contexts'
import {
  formatAddressSuggestions,
  getAddressSuggestions,
} from 'frontastic/contexts/atgCartContext/helpers/httpRequests'
import GoogleSignInSignUp from './google-signin-signup'
import { CheckboxField } from '../forms/checkbox-field'

const registerFormSchema = (deps: { formatMessage: (params: { id: string }) => string }) => {
  const { formatMessage } = deps
  return yup.object().shape({
    email: yup
      .string()
      .required(formatMessage({ id: 'register.validationSchema.email.isRequired' }))
      .email(formatMessage({ id: 'register.validationSchema.email' })),
    password: yup.string().required(formatMessage({ id: 'register.validationSchema.password' })),
    confirmPassword: yup
      .string()
      .required(formatMessage({ id: 'register.validationSchema.confirmPassword.isRequired' }))
      .oneOf([yup.ref('password'), null], formatMessage({ id: 'register.validationSchema.confirmPassword' })),
    firstName: yup
      .string()
      .transform((value) => value.trim())
      .matches(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/, formatMessage({ id: 'validation.firstNameAlphaOnly' }))
      .required(formatMessage({ id: 'validation.firstNameRequired' })),

    lastName: yup
      .string()
      .transform((value) => value.trim())
      .matches(/^[a-zA-Z]+(?:[ '\-][a-zA-Z]+)*$/, formatMessage({ id: 'validation.lastNameAlphaOnly' }))
      .required(formatMessage({ id: 'validation.lastNameRequired' })),
    phoneNumber: yup
      .string()
      .required(formatMessage({ id: 'register.validationSchema.phoneNumber' }))
      .matches(/^[0-9]{10}?$/, formatMessage({ id: 'register.validationSchema.phoneNumber.isRequired' })),
    birthMonth: yup.string(),
    address: yup.string().required(formatMessage({ id: 'register.validationSchema.address.isRequired' })),
    address2: yup.string(),
    city: yup.string().required(formatMessage({ id: 'register.validationSchema.city.isRequired' })),
    state: yup.string().required(formatMessage({ id: 'register.validationSchema.state.isRequired' })),
    zipcode: yup
      .string()
      .required(formatMessage({ id: 'register.validationSchema.zipcode.isRequired' }))
      .matches(/^\d{5}(-\d{4})?$/, formatMessage({ id: 'register.validationSchema.zipcode' })),
    eventsEmail: yup.bool(),
    eventsUSMail: yup.bool(),
  })
}

export interface SignUpFormFields {
  email: string
  password: string
  confirmPassword: string
  firstName: string
  lastName: string
  phoneNumber: string
  birthMonth?: string
  address: string
  address2: string
  city: string
  state: string
  zipcode: string
  country: string
  rewardsClubEmails?: boolean
  eventsEmail?: boolean
  eventsUSMail?: boolean
}

interface formattedAddressType {
  addressLine1: string
  addressLine2: string
  addressLine3: string
  locality: string
  province: string
  postalCode: string
  country: string
}

interface formattedAddressType {
  addressLine1: string
  addressLine2: string
  addressLine3: string
  locality: string
  province: string
  postalCode: string
  country: string
}

interface Props {
  onSubmit: (data: SignUpFormFields) => void
  handleModalToggle: () => void
  handleSignInModalToggle: () => void
  error: string
  formData?: SignUpFormFields
}

export interface Field {
  name:
    | 'email'
    | 'password'
    | 'confirmPassword'
    | 'firstName'
    | 'lastName'
    | 'phoneNumber'
    | 'birthMonth'
    | 'address'
    | 'address2'
    | 'city'
    | 'state'
    | 'zipcode'
  label: string
  type: string
  isRequired?: boolean
}

const SignUpForm: FC<Props> = ({ onSubmit, handleModalToggle, handleSignInModalToggle, error, formData }) => {
  const { formatMessage } = useFormat({ name: 'common' })
  const isMobile = useBreakpointValue({ base: true, md: false })
  const [showTooltip, setShowTooltip] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [isAddressFocused, setIsAddressFocused] = useState(false)
  const [suggestions, setSuggestions] = useState([])
  const { country } = useLocale()
  const defaultValues = formData
    ? { ...formData, country: 'USA' }
    : {
        country: 'USA',
      }

  const { register, handleSubmit, formState, setValue, trigger, getValues, setError, clearErrors, watch } =
    useForm<SignUpFormFields>({
      resolver: yupResolver(registerFormSchema({ formatMessage })),
      mode: 'all',
      defaultValues,
    })

  const { isSubmitting, errors, isValid, touchedFields } = formState
  const stateLabel = formatMessage({ id: 'register.formField.label.state' })
  const birthMonthLabel = formatMessage({ id: 'register.formField.label.birthMonth' })
  const [stateValue, setStateValue] = useState(stateLabel)
  const [birthMonthValue, setBirthMonthValue] = useState(birthMonthLabel)

  const setFormValues = (formattedAddress: formattedAddressType) => {
    if (!formattedAddress) return

    setValue('address', formattedAddress.addressLine1)
    setValue('address2', formattedAddress.addressLine2)
    setValue('city', formattedAddress.locality)
    setValue('state', formattedAddress.province)
    setValue('zipcode', formattedAddress.postalCode)
    setStateValue(states[formattedAddress.province])
    trigger(['address', 'city', 'state', 'zipcode', 'address2'])
  }

  useEffect(() => {
    if (formData?.birthMonth) {
      setBirthMonthValue(birthMonths[formData?.birthMonth])
    }
    if (formData?.state) {
      setStateValue(states[formData?.state])
    }
  }, [formData?.birthMonth, formData?.state])

  const handleSuggestionClick = async (formatUrl: string) => {
    try {
      const formattedAddress: formattedAddressType = {
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        country: '',
        locality: '',
        postalCode: '',
        province: '',
      }
      const res = await formatAddressSuggestions(formatUrl)
      res?.data?.address.forEach((item) => {
        let key = Object.keys(item)[0]
        let value = item[key]
        formattedAddress[key] = value
      })
      setFormValues(formattedAddress)
    } catch (error) {
      console.log(error)
    }
  }

  const rewardLogo = '/images/reward-logo.png'

  const rewardsImage = [
    {
      icon: <EngageIcon width={'auto'} height={45} color="text.primary" />,
      description: formatMessage({ id: 'register.reward.image.description.engage' }),
    },
    {
      icon: <EarnIcon width={'auto'} height={37} color="text.primary" />,
      description: formatMessage({ id: 'register.reward.image.description.earn' }),
    },
    {
      icon: <EnjoyIcon width={'auto'} height={37} color="text.primary" />,
      description: formatMessage({ id: 'register.reward.image.description.enjoy' }),
    },
    {
      icon: <RepeatIcon width={'auto'} height={37} color="text.primary" />,
      description: formatMessage({ id: 'register.reward.image.description.repeat' }),
    },
  ]

  const formFields = [
    {
      name: 'email',
      label: formatMessage({ id: 'register.formField.label.email' }),
      type: 'email',
      isRequired: true,
    },
    {
      name: 'password',
      label: formatMessage({ id: 'register.formField.label.password' }),
      type: 'password',
      isRequired: true,
    },
    {
      name: 'confirmPassword',
      label: formatMessage({ id: 'register.formField.label.confirmPassword' }),
      type: 'password',
      isRequired: true,
    },
    {
      name: 'firstName',
      label: formatMessage({ id: 'register.formField.label.firstName' }),
      type: 'text',
      isRequired: true,
    },
    {
      name: 'lastName',
      label: formatMessage({ id: 'register.formField.label.lastName' }),
      type: 'text',
      isRequired: true,
    },
    {
      name: 'phoneNumber',
      label: formatMessage({ id: 'register.formField.label.phoneNumber' }),
      type: 'text',
      isRequired: true,
    },
    {
      name: 'birthMonth',
      label: formatMessage({ id: 'register.formField.label.birthMonth' }),
      type: 'select',
      isRequired: false,
    },
    {
      name: 'address',
      label: formatMessage({ id: 'register.formField.label.address' }),
      type: 'text',
      isRequired: true,
    },
    {
      name: 'address2',
      label: formatMessage({ id: 'register.formField.label.address2' }),
      type: 'text',
      isRequired: false,
    },
    {
      name: 'city',
      label: formatMessage({ id: 'register.formField.label.city' }),
      type: 'text',
      isRequired: true,
    },
    {
      name: 'state',
      label: formatMessage({ id: 'register.formField.label.state' }),
      type: 'state',
      isRequired: true,
    },
    {
      name: 'eventsEmail',
      label: formatMessage({ id: 'register.formField.label.eventsEmail' }),
      type: 'checkbox',
    },
    {
      name: 'eventsUSMail',
      label: formatMessage({ id: 'register.formField.label.eventsUSMail' }),
      type: 'checkbox',
    },
  ] as const

  const tooltipLabels = [
    formatMessage({ id: 'register.tooltip.password.description1' }),
    formatMessage({ id: 'register.tooltip.password.description2' }),
    formatMessage({ id: 'register.tooltip.password.description3' }),
  ]

  const confirmPasswordValidation = function (value) {
    const password = getValues('password')
    return password === value
  }

  const handleOnChange = async (e: any, field: Field) => {
    if (field.name === 'address' && e.target.value?.length) {
      try {
        const res = await getAddressSuggestions(e.target.value)
        const displaySuggestions = res?.data?.results.map((item: { suggestion: string; format: string }) => ({
          suggestion: item.suggestion,
          formatUrl: item.format,
        }))
        setSuggestions(displaySuggestions)
      } catch (e) {
        console.log('error suggestions:', e)
      }
    }
    if (touchedFields[field.name]) {
      registerFormSchema({ formatMessage })
        .validateAt(field.name, { [field.name]: e.target.value })
        .then(() => clearErrors(field.name))
        .catch((error) => {
          if (field.name === 'confirmPassword') {
            if (!confirmPasswordValidation(e.target.value)) {
              setError(field.name, { message: error.message })
            } else {
              clearErrors('confirmPassword')
            }
          } else {
            setError(field.name, { message: error.message })
          }
        })
    }
  }

  const handleClick = (type: string) => {
    if (type === 'password') {
      setShowPassword(!showPassword)
    } else {
      setShowConfirmPassword(!showConfirmPassword)
    }
  }

  const handleSubmitForm = (data: SignUpFormFields) => {
    onSubmit(data)
  }

  const handleTouchStart = () => {
    setShowTooltip(!showTooltip)
  }

  const handleOnFocus = (field: Field) => {
    if (field.name === 'address') {
      setIsAddressFocused(true)
    }
    if (field.name === 'password') {
      handleTouchStart()
    }
  }

  const handleOnBlur = (field: Field) => {
    if (field.name === 'address') {
      setTimeout(() => {
        setIsAddressFocused(false)
      }, 200)
    }
    if (field.name === 'password') {
      handleTouchStart()
    }
  }

  const handleMenuItem = (e: { target: { value: string } }, field: Field) => {
    if (field.name === 'birthMonth' && e.target.value) {
      setValue('birthMonth', e.target.value)
      setBirthMonthValue(birthMonths[e.target.value])
    }
    if (field.name === 'state' && e.target.value) {
      setValue('state', e.target.value)
      setStateValue(states[e.target.value])
      clearErrors('state')
      trigger('state')
    }
  }

  const handleMenuClose = () => {
    const stateValue = getValues('state')
    if (!stateValue) {
      setError('state', { message: formatMessage({ id: 'register.validationSchema.state.isRequired' }) })
    }
  }

  const handleGoogleSignInSignup = async (response) => {
    try {
      const res = await atgAxiosInstance.post('currentUser/googleOneTapSignIn', {
        credential: response?.credential,
        src: 'login',
      })
      const eventData = {
        events: [
          {
            ...getCAPIrootEventsProps('CompleteRegistration'),
            customer_information: [
              {
                key: 'country',
                value: country || 'US',
              },
            ],
          },
        ],
        partner_agent: 'liveramp',
      }
      addCAPIFbEvent(eventData)
      window.dispatchEvent(new Event('userLoggedIn'))
      handleModalToggle()
    } catch (e) {
      console.log('google signin failed', e)
    }
  }

  return (
    <Box>
      <Stack direction={{ base: 'column', md: 'row' }}>
        <VStack width={'full'} px={4}>
          <Text fontSize="xl" textAlign="center" py={5}>
            {formatMessage({ id: 'register.text.heading.signup' })}
          </Text>
          {error && (
            <Box
              id="#error"
              textAlign={'center'}
              width={'full'}
              my={5}
              rounded="md"
              p={4}
              color={core.danger['danger-900']}
              bgColor={core.danger['danger-200']}
              fontWeight={300}
              fontSize={14}
              fontFamily={'Libre Franklin'}
            >
              {error}
            </Box>
          )}
          <GoogleSignInSignUp buttonText="signup_with" handleLogin={handleGoogleSignInSignup} />
          <Box position="relative" width={'full'} pt={5} pb={0}>
            <Divider variant={'solid'} borderWidth={1} orientation="horizontal" />
            <AbsoluteCenter
              bg={semantic.text['primary-inverse']}
              color={core.primary['primary-300']}
              px={2}
              fontSize={16}
              mt={2}
            >
              {formatMessage({ id: 'register.divider.text.or' })}
            </AbsoluteCenter>
          </Box>
          <Box width={'full'} position={'relative'}>
            <form onSubmit={handleSubmit(handleSubmitForm)} role={'form'} aria-label={'Sign Up'}>
              {formFields.map((field) => (
                <FormControl py={2} key={field.name}>
                  {field.type === 'select' ? (
                    field.name === 'birthMonth' ? (
                      <FormControl py={2}>
                        <Menu>
                          {({ isOpen }) => (
                            <Box>
                              <MenuButton
                                borderRadius={'unset'}
                                borderColor={isOpen ? core.primary['primary-900'] : core.primary['primary-500']}
                                borderWidth={isOpen ? 2 : 1}
                                _hover={{
                                  borderColor: isOpen ? core.primary['primary-900'] : core.primary['primary-200'],
                                }}
                                boxShadow={'none'}
                                _focusVisible={{ boxShadow: 'none' }}
                                width={'100%'}
                                variant={'outlined'}
                                as={Button}
                                rightIcon={<ChevronDownIcon />}
                                textAlign={'start'}
                                fontSize={14}
                                fontWeight={400}
                                fontFamily={'Libre Franklin'}
                                paddingBottom={6}
                                paddingTop={6}
                                textColor={
                                  birthMonthValue !== birthMonthLabel ? 'primary' : core.primary['primary-500']
                                }
                              >
                                {(isOpen || getValues('birthMonth')) && (
                                  <Text
                                    position={'absolute'}
                                    left={3}
                                    top={4}
                                    backgroundColor={'white'}
                                    padding={'0 4px'}
                                    color={'black'}
                                    zIndex={1}
                                    transform={'scale(0.85) translateY(-26px)'}
                                    transition={'transform 0.5s ease-in-out'}
                                    transformOrigin={'left top'}
                                    fontSize={14}
                                    fontWeight={400}
                                    fontFamily={'Libre Franklin'}
                                    textColor={core.primary['primary-500']}
                                  >
                                    {birthMonthLabel}
                                  </Text>
                                )}
                                <Text zIndex={0}>{birthMonthValue}</Text>
                              </MenuButton>
                              <MenuList
                                top={-10}
                                position={'absolute'}
                                style={{ width: '100%' }}
                                rootProps={{ width: '100%' }}
                                zIndex={22222}
                                maxHeight={256}
                                overflowY="auto"
                              >
                                {birthdayArray.map((month, index) => (
                                  <MenuItem
                                    width={'100%'}
                                    key={month.value}
                                    value={month.value}
                                    onClick={(e: any) => handleMenuItem(e, field)}
                                  >
                                    {month.label}
                                  </MenuItem>
                                ))}
                              </MenuList>
                            </Box>
                          )}
                        </Menu>
                      </FormControl>
                    ) : null
                  ) : field.type === 'checkbox' ? (
                    <CheckboxField
                      content={formatMessage({
                        id: field.label,
                      })}
                      checkboxProps={{
                        defaultChecked: true,
                        colorScheme: 'shading',
                        ...register(field.name),
                      }}
                      contentStyle={{
                        fontSize: 14,
                        fontFamily: 'Libre Franklin',
                        fontWeight: 300,
                      }}
                    />
                  ) : field.type === 'state' ? (
                    <Flex gap={5} pb={5} py={2}>
                      <FormControl isInvalid={!!errors?.state?.message}>
                        <Menu onClose={handleMenuClose}>
                          {({ isOpen }) => (
                            <>
                              <MenuButton
                                borderRadius={'unset'}
                                width={'100%'}
                                variant={'outlined'}
                                as={Button}
                                rightIcon={<ChevronDownIcon />}
                                textAlign={'start'}
                                fontSize={14}
                                fontWeight={400}
                                fontFamily={'Libre Franklin'}
                                borderColor={
                                  !!errors?.state?.message
                                    ? core['danger']['danger-500']
                                    : isOpen
                                      ? core.primary['primary-900']
                                      : core.primary['primary-500']
                                }
                                borderWidth={!!errors?.state?.message || isOpen ? 2 : 1}
                                _hover={{
                                  borderColor: !!errors?.state?.message
                                    ? core['danger']['danger-500']
                                    : isOpen
                                      ? core.primary['primary-900']
                                      : core.primary['primary-200'],
                                }}
                                boxShadow={'none'}
                                _focusVisible={{ boxShadow: 'none' }}
                                paddingBottom={6}
                                paddingTop={6}
                                onClick={(e: any) => handleMenuItem(e, field)}
                                textColor={stateValue !== stateLabel ? 'primary' : core.primary['primary-500']}
                              >
                                {stateValue}
                              </MenuButton>
                              <MenuList rootProps={{ width: '100%' }} zIndex={22222} maxHeight={256} overflowY="auto">
                                {statePickerItems.map((state, index) => (
                                  <MenuItem
                                    key={state.value}
                                    value={state.value}
                                    onClick={(e: any) => handleMenuItem(e, field)}
                                  >
                                    {state.label}
                                  </MenuItem>
                                ))}
                              </MenuList>
                            </>
                          )}
                        </Menu>
                        <FormErrorMessage fontFamily={'Libre Franklin'} fontSize={11} fontWeight={400}>
                          {errors?.state?.message}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl
                        isRequired={field?.isRequired || false}
                        variant={'floating'}
                        key={'zipcode'}
                        isInvalid={Boolean(errors.zipcode)}
                      >
                        <InputGroup>
                          <Input
                            paddingTop={4}
                            paddingBottom={5}
                            placeholder={' '}
                            isRequired
                            type={'text'}
                            name={formatMessage({ id: 'register.formField.label.zipcode' })}
                            {...register('zipcode')}
                            borderRadius={0}
                            size={'lg'}
                            inputMode={'tel'}
                            fontSize={14}
                            fontWeight={400}
                            borderColor={core.primary['primary-600']}
                            onChange={(e) =>
                              handleOnChange(e, { name: 'zipcode', label: 'zipcode', isRequired: true, type: 'text' })
                            }
                            _focus={{
                              borderColor: Boolean(errors['zipcode']) ? core['danger'] : core.primary['primary-900'],
                              borderWidth: 2,
                              boxShadow: 'unset',
                            }}
                            _focusVisible={{
                              borderColor: Boolean(errors['zipcode']) ? core['danger'] : core.primary['primary-900'],
                            }}
                          />
                          <FormLabel
                            fontSize={14}
                            fontWeight={400}
                            fontFamily={'Libre Franklin'}
                            textColor={Boolean(errors.zipcode) ? 'text.danger' : core.primary['primary-500']}
                          >
                            {formatMessage({ id: 'register.formField.label.zipcode' })}
                          </FormLabel>
                        </InputGroup>
                        <FormErrorMessage fontFamily={'Libre Franklin'} fontSize={11} fontWeight={400}>
                          {errors?.zipcode?.message}
                        </FormErrorMessage>
                      </FormControl>
                    </Flex>
                  ) : (
                    <Box py={2} position={'relative'}>
                      <FormControl
                        isRequired={field?.isRequired || false}
                        variant={'floating'}
                        key={field.name}
                        isInvalid={Boolean(errors?.[field?.name])}
                      >
                        <InputGroup isolation={'unset'}>
                          <Input
                            paddingTop={4}
                            paddingBottom={5}
                            type={
                              field.name === 'password'
                                ? showPassword
                                  ? 'text'
                                  : 'password'
                                : field.name === 'confirmPassword'
                                  ? showConfirmPassword
                                    ? 'text'
                                    : 'password'
                                  : 'text'
                            }
                            name={field.name}
                            placeholder={' '}
                            {...register(field.name)}
                            borderRadius={0}
                            size={'lg'}
                            fontSize={14}
                            fontWeight={400}
                            borderColor={core.primary['primary-600']}
                            onChange={(e) => handleOnChange(e, field)}
                            onFocusCapture={() => handleOnFocus(field)}
                            onBlurCapture={() => handleOnBlur(field)}
                            inputMode={
                              field.name === 'email' ? 'email' : field.name === 'phoneNumber' ? 'numeric' : 'text'
                            }
                            maxLength={35}
                            onInput={(e: React.FormEvent<HTMLInputElement>) => {
                              const inputValue = e.currentTarget.value
                              if (inputValue.length > 35) {
                                e.currentTarget.value = inputValue.slice(0, 35)
                              }
                            }}
                            _focus={{
                              borderColor: Boolean(errors[field.name]) ? core['danger'] : core.primary['primary-900'],
                              borderWidth: 2,
                              boxShadow: 'unset',
                            }}
                            _focusVisible={{
                              borderColor: Boolean(errors[field.name]) ? core['danger'] : core.primary['primary-900'],
                            }}
                          />
                          {field.name === 'address' && suggestions?.length && isAddressFocused && (
                            <List
                              top={10}
                              left={0}
                              backgroundColor={semantic.text['primary-inverse']}
                              width={'100%'}
                              zIndex={22222}
                              position={'absolute'}
                              justifyItems={'center'}
                              alignItems={'center'}
                              boxShadow={'md'}
                            >
                              {suggestions?.map((suggestion, index) => (
                                <ListItem
                                  _hover={{
                                    backgroundColor: 'gray.200',
                                    textDecoration: 'underline',
                                    cursor: 'pointer',
                                  }}
                                  paddingLeft={5}
                                  py={2}
                                  key={index}
                                  width={'100%'}
                                  onClick={() => handleSuggestionClick(suggestion?.formatUrl)}
                                >
                                  {suggestion?.suggestion}
                                </ListItem>
                              ))}
                            </List>
                          )}
                          <FormLabel
                            fontSize={14}
                            fontWeight={400}
                            fontFamily={'Libre Franklin'}
                            textColor={Boolean(errors?.[field.name]) ? 'text.danger' : core.primary['primary-500']}
                          >
                            {field.label}
                          </FormLabel>
                          <InputRightElement height={'full'} width={'unset'}>
                            {field.type === 'password' && (
                              <HStack gap={0}>
                                {field.name === 'password' && (
                                  <Tooltip
                                    label={tooltipLabels.map((label, index) => (
                                      <Text color={'text.primary'} textStyle={'body-50'} key={index}>
                                        {label}
                                      </Text>
                                    ))}
                                    placement="bottom"
                                    rounded="md"
                                    hasArrow={true}
                                    isOpen={showTooltip}
                                    backgroundColor={semantic.text['primary-inverse']}
                                    boxShadow="md"
                                    arrowSize={16}
                                    p={2}
                                    borderWidth={1}
                                    borderColor={core.primary['primary-200']}
                                    arrowShadowColor={core.primary['primary-200']}
                                    onFocus={handleTouchStart}
                                  >
                                    <Box></Box>
                                  </Tooltip>
                                )}
                                <Button variant={'outlined'} size="sm" onClick={() => handleClick(field.name)}>
                                  {field.name === 'password'
                                    ? showPassword
                                      ? 'Hide'
                                      : 'Show'
                                    : showConfirmPassword
                                      ? 'Hide'
                                      : 'Show'}
                                </Button>
                              </HStack>
                            )}
                          </InputRightElement>
                        </InputGroup>
                        {field.name === 'phoneNumber' && (
                          <Text>{formatMessage({ id: 'register.formField.phonNumber.example' })}</Text>
                        )}
                        <FormErrorMessage fontFamily={'Libre Franklin'} fontSize={11} fontWeight={400}>
                          {errors?.[field?.name]?.message}
                        </FormErrorMessage>
                      </FormControl>
                    </Box>
                  )}
                </FormControl>
              ))}
              <Button
                type="submit"
                isLoading={isSubmitting}
                loadingText="Submitting"
                width={'100%'}
                borderRadius={0}
                onClick={handleSubmit(handleSubmitForm)}
                fontSize={14}
                isDisabled={!isValid}
                _disabled={{ backgroundColor: core.primary['primary-900'], opacity: 0.7 }}
                _hover={{ opacity: 0.5 }}
              >
                {formatMessage({ id: 'register.cta.signup.button' })}
              </Button>
            </form>
          </Box>
          <Flex pb={5} pt={4}>
            <Text>{formatMessage({ id: 'register.text.signinlink' })}</Text>
            <Text
              onClick={handleSignInModalToggle}
              as={'ins'}
              pl={2}
              _hover={{ cursor: 'pointer', textDecoration: 'none' }}
            >
              {formatMessage({ id: 'register.cta.signin' })}
            </Text>
          </Flex>
        </VStack>
      </Stack>
    </Box>
  )
}
export default SignUpForm
